export function parse_employee_details_data_from_view(data:any) {
	const parsed_data = {
		name: data.employee_full_name,
		dob: data.employee_dob,
		product_house: data.case_product_house,
		emp_membership_number: data.emp_membership_number,
		emp_national_id: data.emp_national_id,
		phone: data.comm_phone,
		email: data.comm_email
	}
	return parsed_data;
}

export function parse_employee_details_data_from_select(data) {
	const parsed_data: any = {
		// name: data.first_name == null ? data.last_name : data.first_name + ' ' + data.last_name,
		name: data.full_name,
		dob: data.date_of_birth,
		product_house: '',
		emp_membership_number: data.membership_number,
		emp_national_id: data.id_number,
		phone: '',
		email: '',
	}
	if (data.divorg_id) {
		parsed_data.div_org = data.divorg_id;
	}
	return parsed_data;
}

export function parse_site_contact_details_data_from_view(data) {
	const parsed_data = {
		name: data.cont_person_full_name,
		national_id: data.cont_person_id_number,
		dob: data.cont_person_dob,
		location: data.cont_person_site_name,
		role: data.contact_role,
		phone: data.comm_phone_contact,
		email: data.comm_email_contact
	}
	return parsed_data
}

export function parse_site_contact_details_data_from_select(data) {
	const parsed_data = {
		name: data.con_person_full_name,
		national_id: data.national_id,
		dob: data.dob,
		location: data.site_name,
		role: data.site_con_per_con_type,
		phone: '',
		email: ''
	}
	return parsed_data;
}

export function parse_pot_mem_details_data_from_view(data) {
	const parsed_data = {
		first_name: data.potential_member_full_name.split(' ').slice(0,-1).join(' '),
		last_name: data.potential_member_full_name.split(' ').slice(-1).join(' '),
		id_type: data.potential_member_id_number != '' ? 'National ID': 'Passport',
		id_number: data.potential_member_id_number != '' ? data.potential_member_id_number : data.potential_member_passport,
		gender: data.potential_member_gender,
		dob: data.potential_member_dob,
		phone: '',
		email: ''
	}	
	return parsed_data
}

export function parse_pot_mem_details_data_from_select(data) {
	const parsed_data = {
		first_name: data.first_name,
		last_name: data.last_name,
		id_type: data.identification_type,
		id_number: data.id_number != '' ? data.id_number : data.passport_number,
		gender: data.gender,
		dob: data.dob,
		phone: data.cellphone,
		email: data.email
	}
	return parsed_data;
}

export function parse_busorg_details_data_from_select(data) {
	const parsed_data = {
		category: data.category,
    company_name: data.company_name,
    company_role_type: data.company_role_type,
    holding_company: data.holding_company,
    industry: data.industry,
    registration_date: data.registration_date,
    registration_number: data.registration_number,
    trading_name: data.trading_name
	}
	return parsed_data;
}


export function parse_claimant_individual_details_data_from_select(data) {
	const parsed_data = {
		dob: data.dob,
		first_name: data.first_name,
		full_name: data.full_name,
		gender: data.gender,
		idnumber: data.idnumber,
		idtype: data.idtype,
		individual_id: data.individual_id,
		individual_type: data.individual_type,
		last_name: data.last_name,
		nationality: data.nationality,
		passport_number: data.passport_number,
		phone_number1: data.phone_number1,
		phone_number2: data.phone_number2,
		phone_number3: data.phone_number3,
	}
	return parsed_data;
}