export function taskClassificationSerializerByName(data) {
  return {
    'task_function_type': data.classification_l1_value,
    'task_function_subtype': data.classification_l2_value,
    'task_function_l3': data.classification_l3_value,
    'task_function_l4': data.classification_l4_value,
    'task_function_l5': data.classification_l5_value
  }
}

export function taskClassificationDeserializerById(data){
  return {
    'classification_l1': data.task_function_type,
    'classification_l2': data.task_function_subtype,
    'classification_l3': data.task_function_l3 ? data.task_function_l3: null,
    'classification_l4': data.task_function_l4 ? data.task_function_l4: null,
    'classification_l5': data.task_function_l5 ? data.task_function_l5: null
  }
}

export function caseClassificationSerializerByName(data) {
  return {
    'classification_l1': data.classification_l1_value,
    'classification_l2': data.classification_l2_value,
    'classification_l3': data.classification_l3_value,
    'classification_l4': data.classification_l4_value,
  }
}

export function caseClassificationDeserializerById(data){
  return {
    'classification_l1': data.classification_l1,
    'classification_l2': data.classification_l2,
    'classification_l3': data.classification_l3,
    'classification_l4': data.classification_l4,
  }
}

export const taskNameConfig = {
  // l1:{'name':'Task Function Type', 'class':'col-3'},
  l2:{'name':'Business Function', 'class':'col-3'},
  l3:{'name':'Function Area', 'class':'col-3'},
  l4:{'name':'Area', 'class':'col-3'},
  l5:{'name':'Sub Area', 'class':'col-3'}
}
